.editor-wrapper {
  font-family: "Inter", sans-serif;
  line-height: 1.43;
  font-weight: 400;
  letter-spacing: 0.01071em;
}

.editor-t-wrapper {
  font-family: "PoppinsRegular", "Helvetica", "Arial", sans-serif;
  position: relative;
}

.editor-t-wrapper strong,
.editor-t-wrapper strong * {
  font-family: "PoppinsBold", "Helvetica", "Arial", sans-serif;
}

.ml-1 {
  margin-left: 10px;
}

.dflex {
  display: flex;
}

.alignCenter {
  align-items: center;
}

blockquote {
  border-left: 3px solid transparent;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  margin: 0px;
}

.editor-wrapper table,
.editor-wrapperth,
.editor-wrapper td {
  border: 1px solid transparent;
}

.editor-wrapper table {
  border-collapse: separate;
}

.editor-wrapper .editor-wrapperbutton {
  background-color: white;
  border: none;
}

.editor-wrapper .btnActive {
  opacity: 1;
}

.editor-wrapper {
  min-height: 400px;
  height: fit-content;
  max-width: 100%;
  border: none !important;
  z-index: 1;
}

.editor-wrapper table {
  /* width:100%; */
}

.popup-wrapper1 {
  display: inline;
  position: relative;
}

.af-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  /* border: 1px solid lightgray; */
  height: fit-content !important;
  z-index: 999;
  width: 300px;
}

.editor-wrapper button {
  cursor: pointer;
}

.editor-wrapper code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editor-wrapper html {
  scroll-behavior: smooth;
}

.editor-wrapper *:focus-visible {
  outline: none;
}

.grid-container {
  display: flex;
  border-radius: 0px;
  background-color: transparent;
  border: 0px solid #e5eaf2;
  padding: 0px;
  position: relative;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-size: cover;
}

.grid-container-toolbar,
.grid-item-toolbar {
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.grid-container-toolbar {
  right: -32px;
  top: 0;
  height: fit-content;
}

.grid-item-toolbar {
  left: 0px;
}

.element-toolbar {
  position: absolute;
  right: 0;
  top: -14px;
  z-index: 1000;
}

.element-toolbar a {
  margin-right: 4px;
}

.grid-container-toolbar button,
.grid-item-toolbar button,
.element-toolbar button,
.element-toolbar a {
  border-radius: 10px;
  border: 1px solid rgba(37, 99, 235, 0.32);
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
  font-size: 12px;
  margin-bottom: 2px;
  padding: 6px;
}
.element-toolbar button svg,
.element-toolbar a svg,
.grid-container-toolbar button svg,
.grid-item-toolbar button svg {
  width: 16px;
  height: 16px;
}

.grid-container-toolbar button:hover,
.grid-item-toolbar button:hover,
.element-toolbar button:hover,
.element-toolbar a:hover {
  background-color: #ffffff;
  color: #2684ff;
  border: 1px solid #2684ff;
}

.grid-container-toolbar-hide button {
  display: none !important;
}

.no-border-button button,
.no-border-button button:hover {
  border: none;
}

.grid-item {
  padding: 0px;
  position: relative;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.accordion-container {
  padding: 0px;
}

.accordion-title {
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.accordion-content {
  padding-left: 13px;
  background-color: transparent;
  border-left: 1px solid #ccc;
  margin-left: 14px;
}

.accordion-summary-container {
  padding: 8px 0px;
  margin: 0px;
  position: relative;
  padding-left: 8px;
}

.accordion-details-container {
  margin: 0px;
  padding: 8px;
}

.signature-btn-container button {
  background-color: #0052cc;
  padding: 8px 12px;
  color: #ffffff;
  font-weight: bold;
  opacity: 1;
  border-radius: 32px;
}

.signature-btn-container button:hover {
  background-color: #0052cc;
}

.signature-btn-container .sign-placeholder {
  display: none; /* will changed to flex on DnD */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #E4E6E9;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 16px 0px #0000000D;
  gap: 10px;
}

.signature-btn-grps {
  display: flex;
  justify-content: space-around;
}

.signature-btn-grps svg {
  height: 17px !important;
  width: 17px !important;
}

.react-datepicker__input-container input {
    height: 40px !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.close-popupbtn {
  border-radius: 4px !important;
  width: 24px;
  height: 24px;
}

.close-popupbtn svg {
  width: 17px;
  height: 17px;
}

.popupTitle {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
}

.signature-tab-content {
  display: flex;
  margin: 12px 0px;
  padding: 0px 5px;
  border: 0px solid #ccc;
  min-height: 211px;
}

.signature-signed-wrapper {
  position: relative;
  display: flex;
}

.signature-signed-span {
  position: relative;
}

.signature-signed-wrapper .signed-btn-del {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 1;
  z-index: 1;
  display: none;
}
.signature-signed-wrapper:hover .signed-btn-del {
  display: block;
}

.signed-btn {
  text-align: center;
  opacity: 1;
}

.signed-text {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
}

.typesignature-fontfamily .MuiButtonBase-root {
  opacity: 1;
  border: 1px solid #cccccc6b;
  color: #0f172a;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.08);
}

.typesignature-fontfamily .MuiButtonBase-root.active {
  border: 1px solid #0052cc;
}

.typesignature-input-wrapper .MuiInputAdornment-root .MuiButtonBase-root {
  background-color: #e9ebf8 !important;
  border-radius: 4px !important;
  width: 24px;
  height: 24px;
  padding: 2px;
}

.typesignature-input-wrapper .MuiInputAdornment-root svg {
  width: 100%;
  height: 100%;
}

.signature-canvas {
  height: 100%;
  width: 100%;
  border: 1px solid #2563EB;
  border-radius: 12px;
}

.activeBrush svg {
  stroke: #2563eb;
}

.react-datepicker__input-container {
  display: flex !important;
  align-items: center;
}

.react-datepicker__input-container input {
  height: 27px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.brushcolorpic {
}

.sizeIcons .MuiButtonBase-root {
  opacity: 1;
}

.sizeIcons .MuiButtonBase-root.active .MuiSvgIcon-root {
  fill: #0052cc;
}

.dialog-actions-si .MuiButtonBase-root {
  opacity: 1;
  padding: 6px 5px 0px 3px !important
}

.signature-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.signature-tab2 {
  /* display: flex; */
  align-items: center;
  border-width: 0px, 0px, 0px, 0px;
  border-style: solid;
  border-color: #2563EB;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;
  padding: 10px;
}

.upload-sign-img-wrapper {
  text-align: center;
}

.signature-tab img {
  width: 150px;
  height: auto;
}

.editor-btn-wrapper {
  position: relative;
}

.editor-btn-wrapper .moreBtnShow {
  opacity: 0;
}

.editor-btn-wrapper:hover .moreBtnShow {
  opacity: 1;
}

.editor-btn-wrapper .activeBtnShow {
  opacity: 1;
}

.editor-btn {
  opacity: 1;
}

.editor-btn-options .MuiList-root {
  display: flex;
}

.input-adorn .MuiInputAdornment-root .MuiIconButton-root.active {
  background-color: #ccc;
}

.empty-carousel-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  border: 1px solid rgba(37, 99, 235, 0.32);
}

.empty-carousel-wrapper .carousel-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(37, 99, 235, 0.32);
  margin: 12px;
  padding-bottom: 24px;
}

.empty-carousel-wrapper .carousel-item-inner {
  width: 100%;
  position: relative;
}

.editor-chip-text {
  padding: 4px;
  background-color: #ccc;
}

.drawer-menu {
  display: inline-flex;
}

.MuiButton-root.primaryBtn {
  background: #2563eb !important;
  box-shadow: 0px 8px 24px rgba(30, 64, 175, 0.08);
  border-radius: 8px !important;
  height: 28px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #ffffff !important;
  border: 1px solid #2563eb !important;
  width: auto !important;
  padding: 2px 0px 0px 0px !important;
  min-width: 89px !important;
  font-family: Inter, sans-serif;
  text-transform: none;
}

.MuiButton-root.primaryBtn.disabled,
.MuiButton-root.secondaryBtn.disabled {
  background: #eee !important;
  color: #ccc !important;
  border: 1px solid #ccc !important;
}

.MuiButton-root.secondaryBtn {
  background: #ffffff;
  border: 1px solid #2563eb !important;
  box-shadow: 0px 8px 24px rgba(30, 64, 175, 0.08);
  border-radius: 8px !important;
  height: 28px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-right: 15px !important;
  color: #2563eb;
  width: auto !important;
  padding: 2px 0px 0px 0px !important;
  min-width: 89px !important;
  font-family: Inter, sans-serif;
  text-transform: none;
}

.deleteBtn {
  background: #ffffff;
  border: 1px solid #d32f2f !important;
  box-shadow: 0px 8px 24px rgba(30, 64, 175, 0.08);
  border-radius: 8px !important;
  height: 28px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-right: 15px !important;
  color: #d32f2f;
  width: auto !important;
}

@media (max-width: 480px) {
  body.editorbody {
    padding: 0px !important;
  }

  .app-logo {
    justify-content: end;
  }

  .editor-t-wrapper {
    padding: 0px 0px !important;
  }

  .toolbar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .toolbar-grp1,
  .toolbar-grp1 > div {
    display: flex;
  }

  .page-builder .editor-wrapper {
    max-width: 100% !important;
    margin-top: 0px !important;
    padding: 0px !important;
  }

  .embed {
    justify-content: center;
    width: 100%;
  }

  .embed img {
    object-fit: contain;
  }

  form .form-field {
    width: 100% !important;
  }

  .form-btn-wrpr {
    justify-content: center !important;
  }
  .section-wrapper-fluid .grid-container {
    width: 100% !important;
  }
  .app-drawer-btn {
    color: #000;
  }

  .dialog-actions-si {
    display: block !important;
  }
  
  .dialog-actions-si .actionBtn {
    margin-top: 24px;
  }
}

.editorTabs {
  height: 40px;
  min-height: 40px;
}

.editorTabs .MuiTab-root {
  min-width: 80px;
  min-height: 30px !important;
  font-size: 14px;
  /* max-width: 130px; */
  /* padding: 6px 6px !important; */
  color: #0f172a;
  text-transform: capitalize;
}

.editorTabs .MuiTabScrollButton-horizontal.Mui-disabled {
  display: none;
}

.editorTabs .MuiTabs-scroller {
  border-bottom: 1px solid #8080801c;
}

.btnColorPicker .MuiOutlinedInput-input,
.tablePopup .MuiOutlinedInput-input {
  padding: 8.5px 14px;
}

.toolbar svg {
  /* fill: 'red' */
}

.MuiIconButton-root.btnActive {
  background-color: #ccc;
}

.embed .element-toolbar {
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: #000000b6;
  padding: 6px 10px 2px 10px;
  border-radius: 8px 8px 0 0;
}

.embed-code .element-toolbar {
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.resize-br {
  position: absolute !important;
  bottom: 2px;
  right: 2px;
}

.visible-on-hover {
  display: none !important;
}

.has-hover:hover .visible-on-hover {
  display: flex !important;
}

.left-top-editor-wrapper,
.left-bottom-editor-wrapper {
  margin-top: 1%;
  margin-left: 85px;
  max-width: calc(100% - 85px) !important;
}

.right-top-editor-wrapper,
.right-bottom-editor-wrapper {
  margin-top: 1%;
  margin-right: 85px;
  max-width: calc(100% - 85px) !important;
}

.bottom-left-editor-wrapper,
.bottom-right-editor-wrapper {
  margin-top: 1%;
}

.editor-wrapper-toolbar-closed .left-top-toolbar-wrapper,
.editor-wrapper-toolbar-closed .left-bottom-toolbar-wrapper {
  left: 2%;
  height: 100%;
  width: fit-content;
  transition: all 0.5s;
}

.editor-wrapper-toolbar-closed .right-top-toolbar-wrapper,
.editor-wrapper-toolbar-closed .right-bottom-toolbar-wrapper {
  right: 2% !important;
  height: 100%;
  width: 50px;
  transition: all 0.5s;
}

.editor-wrapper-toolbar-closed .top-left-editor-wrapper-toolbar-closed,
.editor-wrapper-toolbar-closed .top-right-editor-wrapper-toolbar-closed {
  margin-top: 1% !important;
  transition: all 0.5s;
}

.editor-wrapper-toolbar-closed .right-bottom-editor-wrapper-toolbar-closed,
.editor-wrapper-toolbar-closed .right-top-editor-wrapper-toolbar-closed {
  margin-top: 10px !important;
  max-width: calc(100% - 0px) !important;
  margin-right: 3%;
  transition: all 0.5s;
  width: 100%;
}

.editor-wrapper-toolbar-closed .left-bottom-editor-wrapper-toolbar-closed,
.editor-wrapper-toolbar-closed .left-top-editor-wrapper-toolbar-closed {
  margin-top: 10px !important;
  max-width: calc(100% - 120px);
  margin-left: 2%;
  transition: all 0.5s;
}

.element-root .element-selector {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  border: 2px solid #2563eb;
  display: none;
  pointer-events: none;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.element-selector .element-selector-dots {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #2563eb;
  background-color: #2563eb;
}

.element-selector-dots.tl {
  left: -5px;
  top: -5px;
  border: 2px solid #2563eb;
}

.element-selector-dots.tr {
  right: -5px;
  top: -5px;
  border: 2px solid #2563eb;
}

.element-selector-dots.bl {
  left: -5px;
  bottom: -5px;
  border: 2px solid #2563eb;
}

.element-selector-dots.br {
  right: -5px;
  bottom: -5px;
  border: 2px solid #2563eb;
}

.element-selector-ctrl.tc {
  position: absolute;
  right: 0;
  left: 0;
  top: -15px;
  margin: auto;
  width: 24px;
  height: 24px;
}

.element-selector-ctrl.bc {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -15px;
  margin: auto;
  width: 24px;
  height: 24px;
}

.element-selector-ctrl button,
.element-selector-ctrl button:hover {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(37, 99, 235, 0.32);
  background: #fff;
  z-index: 100;
}

.element-selector.selected {
  display: block;
}

.element-toolbar button {
  margin-right: 4px;
}

.empty-carousel-wrapper .element-selector {
  width: 100%;
}

.empty-carousel-wrapper .grid-container-toolbar {
  right: 0px;
}

.empty-carousel-wrapper .grid-item-toolbar {
  left: 0px;
}

.drag-overlay .element-selector {
  display: none;
}

.drop-overlay {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: all 1s;
}

.dragging.drop-overlay {
  pointer-events: auto;
}

.drop-overlay.active {
  position: relative;
  background-color: #2684ff;
}

.dragging.active_drag .grid-c-wrpr {
  opacity: 0.15;
}

.optionePopupHeader {
  border-bottom: 1px solid #dce4ec;
}

.dialogComp .MuiInputBase-input {
  border-radius: 8px;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.05);
  font-size: 12px;
  font-weight: 500;
}

.iconRadioButton .MuiRadio-root {
  visibility: hidden;
  width: 0px;
  padding: 6px;
}

.iconRadioButton .MuiFormControlLabel-root {
  margin-right: 8px;
}

.iconRadioButton .MuiTypography-root {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRadioButton .MuiFormControlLabel-root.active .fillPath {
  fill: #2684ff;
}

.iconRadioButton .MuiFormControlLabel-root.active .fillStroke {
  stroke: #2684ff;
}

.iconRadioButton .Mui-checked ~ .MuiTypography-root .fillStroke {
  stroke: #2684ff;
}

.selctedBtnIcon {
  border: 1px solid #ececec;
  border-radius: 4px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.borderInput {
  text-align: center;
}

.removeScroll::-webkit-outer-spin-button,
.removeScroll::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
.removeScroll {
    -moz-appearance: textfield;
}

.borderInput:focus-visible {
  outline: none !important;
}

.bannerSpaceBoxLeft {
  width: 12px;
  height: 80px;
  border-radius: 2px 8px 8px 2px;
  border: 2px solid #2563eb;
  top: 14px;
  position: absolute;
  left: 0;
}

.bannerSpaceBoxTop {
  height: 12px;
  width: 86px;
  border-radius: 2px 2px 8px 8px;
  border: 2px solid #2563eb;
  position: absolute;
  left: 9px;
  top: 0;
}
.bannerSpaceBoxRight {
  width: 12px;
  height: 80px;
  border-radius: 8px 2px 2px 8px;
  border: 2px solid #2563eb;
  position: absolute;
  right: 0;
  top: 14px;
}
.bannerSpaceBoxBottom {
  height: 12px;
  width: 86px;
  border-radius: 8px 8px 2px 2px;
  border: 2px solid #2563eb;
  position: absolute;
  left: 9px;
  bottom: 0;
}
.bannerSpaceBox {
  position: relative;
  width: 106px;
  height: 106px;
  flex-shrink: 0;
}

.sliderInput {
  width: 66px !important;
  padding: 2px 10px;
  margin-left: 18px;
  box-shadow: 0px 4px 16px 0px #0000000d;
  border: 1px solid #6f6f6f33;
  border-radius: 4px;
}
.sld-wrpr {
  padding-left: 16px;
}
.cradio-primary .MuiRadio-root {
  color: #e0e0e0;
}
.cradio-primary .MuiRadio-root.Mui-checked,
.ccheckbox-primary .MuiCheckbox-root.Mui-checked {
  color: #2563eb;
}

.spacingSlider .MuiSlider-thumb {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  box-shadow: 0px 1px 6px #aaa;
}
.spacingSlider {
  height: 9px !important;
  color: #2563eb !important;
}

.uploadImageSection {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.uploadImageSection .removeImageText {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  text-align: center;
}

.uploadImageSection:hover .removeImageText {
  visibility: visible;
  opacity: 1;
  text-align: center;
}

.uploadImageText,
.removeImageText {
  font-size: 16px;
  font-weight: 400;
  width: 200px;
  cursor: pointer;
  color: #464646;
  background-color: #fff;
  padding: 4px 6px;
  border-radius: 12px;
  span {
    text-transform: capitalize;
  }
  @media only screen and (max-width: 599px) {
  background-color: unset;
  display: flex;
  justify-content: center;
}
}

.textFontArrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
}

.textFontArrows svg {
  width: 12px;
  height: 12px;
}

.textFontArrows .MuiIconButton-root {
  padding: 2px;
}

.tools-drawer .MuiTypography-root {
  color: #000000;
}

.img_upload_btn_list .btn--wrpr {
  background-color: transparent !important;
}

/** to avoid line space **/
.page-settings {
  height: 0px;
}

.sidebar-wrpr-eds {
  padding: 8px 0px;
}
.sidebar-wrpr-eds .MuiTypography-body1 {
  color: #0f172a;
  font-size: 14px;
}

.content-editable.empty {
  position: relative;
}
.content-editable.empty:after {
  content: attr(placeholder);
  position: absolute;
  left: 0;
  top: 0;
}
.content-editable.empty.np:after {
  left: auto;
  top: auto;
}
.af-pl-1::after {
  padding-left: 12px;
}
.af-pl-2::after {
  padding-left: 24px;
}
.af-pt-2::after {
  padding-top: 8px;
}

/* HTML: <div class="loader"></div> */
.img-loader-wrapper {
  width: 28px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f10c49;
  animation: l9 2s infinite;
}
@keyframes l9 {
  0% {
    box-shadow:
      0 -16px #f4dd51,
      calc(16px * 0.707) calc(-16px * 0.707) #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
  12.5% {
    box-shadow:
      0 0 #f4dd51,
      calc(16px * 0.707) calc(-16px * 0.707) #e3aad6,
      16px 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
  25% {
    box-shadow:
      0 0 #f4dd51,
      0 0 #e3aad6,
      16px 0 #f4dd51,
      calc(16px * 0.707) calc(16px * 0.707) #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
  37.5% {
    box-shadow:
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      calc(16px * 0.707) calc(16px * 0.707) #e3aad6,
      0 16px #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
  50% {
    box-shadow:
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 16px #f4dd51,
      calc(-16px * 0.707) calc(16px * 0.707) #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
  62.5% {
    box-shadow:
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      calc(-16px * 0.707) calc(16px * 0.707) #e3aad6,
      -16px 0 #f4dd51,
      0 0 #e3aad6;
  }
  75% {
    box-shadow:
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      -16px 0 #f4dd51,
      calc(-16px * 0.707) calc(-16px * 0.707) #e3aad6;
  }
  87.5% {
    box-shadow:
      0 -16px #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      calc(-16px * 0.707) calc(-16px * 0.707) #e3aad6;
  }
  100% {
    box-shadow:
      0 -16px #f4dd51,
      calc(16px * 0.707) calc(-16px * 0.707) #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6,
      0 0 #f4dd51,
      0 0 #e3aad6;
  }
}

.doublequote::before {
  content: '\201C';
}
.doublequote::after {
  content: '\201D';
}

.scrollSmooth {
  scroll-behavior: smooth;
}

.freegrid-section {
  max-width: 100% !important;
}

.divider-settings {
  display: none;
  position: absolute;
}

.dividerComponent:hover {
  padding: 10px 0;
}

.dividerComponent:hover .divider-settings {
  display: block;
}

@media (max-width: 899px) {
  .MuiPopover-root {
    z-index: 1302 !important;
  }
}